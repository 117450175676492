
import SkeletonLoader from './SkeletonLoader.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IListableProductJson } from '../../../../types/Product';
import { LoaderTypeOptions } from '../../../../constants/skeleton-loader';
import { SkeletonLoaderType } from '../../../../types/UiComponents';

@Component({
	name: 'SkeletonLoaderWrapper',
	components: {
		SkeletonLoader
	}
})
export default class SkeletonLoaderWrapper extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Boolean, default: false })
	readonly horizontal: boolean;

	/* Computed
	============================================*/
	@Get('searchResult@products')
	products: IListableProductJson[];


	get isDesktop() {
		return this.$mq === 'desktop';
	}

	get numberOfSkeletonLoaders(): number {
		return this.products?.length || 12;
	}

	get wrapperClass(): string {
		return this.horizontal
			? 'horizontal-placeholders-wrapper'
			: 'product-list-grid product-placeholders-wrapper';
	}

	get loaderType(): SkeletonLoaderType {
		return this.horizontal
			? LoaderTypeOptions.horizontal
			: LoaderTypeOptions.product;
	}

}
